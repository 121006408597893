import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  typographyStyle: {
    fontfamily: 'Lato',
    color: 'grey',
    fontSize: '1.5rem',
    textAlign: 'left',
    fontWeight: 400,
  }
});

export const Faq = (props) => {
  const classes = useStyles();
  return (
    <div id='faq'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Frequently Asked Question</h2>
        </div>
          {props.data? props.data.map((d, i) => (
          <Accordion>
              <AccordionSummary
                key={`${d.que}-${i}`}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.typographyStyle}>{d.que}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.typographyStyle}>
                  {d.text}
                </Typography>
              </AccordionDetails>
        </Accordion>
          )) : "loading"}
      </div>
    </div>
  )
}
