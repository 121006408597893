export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-to'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className= 'navbar-brand-logo' href='#page-top'>
          <img src="img/logo.jpg" height={70} width={70} alt="logo"/>
          </a>
          <a className='navbar-brand-text page-scroll' href='#page-top'>
            Home
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                Background
              </a>
            </li>
            <li>
              <a href='#document' className='page-scroll'>
                Documents
              </a>
            </li>
            <li>
              <a href='#blog' className='page-scroll'>
                BLOG
              </a>
            </li>
            <li>
              <a href='#faq' className='page-scroll'>
                FAQ
              </a>
            </li>            
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
