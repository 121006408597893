import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

//export const Photos = (props) => {
export const Document = (props) =>{
  return (
    <div id='document' className='text-center'>
      <div className='container'></div>
        <div className='section-title'>
              <h2>Documents</h2>
              <p>
                Download any of the below documents for your own records.
              </p>
      </div>,
      <div className='row'>
        <div className='portfolio-items'></div>
        <Grid container justifyContent="center">
            {props.data
            ? props.data.map((d, i) => (
                <Grid  key={`${d.image}-${i}`} item xs={0} sm={12} md={6} lg={3}>
                <Card sx={{
                  border: 1,
                  Width: 240,
                  p: 2
                }} className='document-items'>
                  <CardMedia
                    component={d.component} //What type of component is this
                    height={d.height} //Image height
                    image={d.image} //Document image"
                    alt={d.alt} //Alternative image text"
                  />
                  <CardContent>
                  <Typography gutterBottom variant="h3" component="div">
                      {d.docname}
                      {/* What is the documents formal name */}
                  </Typography>
                  <Typography variant="body" color="text.secondary">
                      {d.docabout}  
                      {/* What is the document content all about */}
                  </Typography>
                  </CardContent>
                  <CardActions>
                    {/*<a href='docs/testdoc.pdf'>Open Document</a>*/}
                    {/*<td onClick={()=> window.open('docs/testdoc.pdf')}>Open Document</td>*/}
                    <Button variant="outlined" size="small" onClick={()=> window.open(d.document)}>Open Document</Button>
                  </CardActions>
                </Card></Grid>))
                : 'Loading...'}
                </Grid>
        </div>
    </div>
  );
}